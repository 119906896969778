import { createMuiTheme } from "@material-ui/core/styles";
//import blue from "@material-ui/core/colors/blue";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#5a8ec3',
      main: '#236192',
      dark: '#003764',
      contrastText: '#fff',
    },
    secondary: {
      main: '#ff8200',
    },
  },});
  
export default theme;
